<template>
    <div>
        <span v-if="$lang === 'pt'">Primeiramente, para criar um comando, você deve criar um arquivo dentro da sua pasta de comandos, onde o nome do arquivo deve ser o nome do seu comando que foi registrado no <span class="text-cmd">slash.js</span> com a extenção .js no final.<br>Neste arquivo, você poderá criar o seu comando.
            <br>
            <v-spacer />
            <br>
            Estrutura de um comando
            <v-icon color="white" :class="`mdi ${drop ? 'mdi-chevron-down' : 'mdi-chevron-right'} my-auto mx-2`" @click="drop = !drop" />
        </span>
        <span v-else>First of all, to create a command, you have to create a file inside your commands folder, where the name of the file must be the name of your command that you registered in the <span class="text-cmd">slash.js</span> with the extention .js at the end.<br>In this file, you will be able to create your command.
            <br>
            <v-spacer />
            <br>
            Command structure
            <v-icon color="white" :class="`mdi ${drop ? 'mdi-chevron-down' : 'mdi-chevron-right'} my-auto mx-2`" @click="drop = !drop" />
        </span>
        <div v-if="drop">
            <br>
            <p>
                <img class="flex" :src="$lang === 'pt' ? image[0] : image[1]" />    
            </p>
        </div>
        <span v-if="$lang === 'pt'" class="mt-8">
            <br>
            <v-spacer />
            <br>
            Para adicionar um comando da lista de comandos abaixo, registre o comando no <span class="text-cmd">slash.js</span>, crie um arquivo na sua pasta de comandos com o nome do comando com .js no final e insira o código do comando nesse arquivo.
            <br>
            <v-spacer />
            <br>
            Lista de comandos disponívels:<br><br>
            <ul v-for="(cd, i) in commands" :key="i" class="mb-16">
                <li>
                    {{cd.name}}<br>
                    Descrição: {{cd.info}}<br>
                    Categoria: {{cd.category}}
                    <span v-if="cd.slash">
                        <br>Comando no <span class="text-cmd">slash.js</span>
                        <v-icon color="white" :class="`mdi ${cd.dropslash ? 'mdi-chevron-down' : 'mdi-chevron-right'} my-auto mx-2`" @click="cd.dropslash = !cd.dropslash" />
                        <div v-if="cd.dropslash">
                            <br>
                            <p>
                                <img class="flex" :src="cd.slash" />
                            </p>
                        </div>
                    </span>
                    <br><v-btn variant="solo" elevation="0" color="#fafa52" class="mt-4 custombtn" @click="cd.drop = !cd.drop"><b>{{cd.drop ? 'Esconder' : 'Mostrar'}} Código</b></v-btn>
                    <pre v-if="cd.drop" class="precmd bg-white my-8 px-2 overflow-auto">
                        {{codes[i]}}
                    </pre>
                </li>
            </ul>
        </span>
        <span v-else class="mt-8">
            <br>
            <v-spacer />
            <br>
            To add a command from the list bellow, register the command in the <span class="text-cmd">slash.js</span>, create a file in your commands folder with the name of the command with .js at the end and paste the code inside the file.
            <br>
            <v-spacer />
            <br>
            Avilable Commands:<br><br>
            <ul v-for="(cd, i) in commandsen" :key="(i+commands.length)" class="mb-16">
                <li>
                    {{cd.name}}<br>
                    Description: {{cd.info}}<br>
                    Category: {{cd.category}}
                    <span v-if="cd.slash">
                        <br>Command in <span class="text-cmd">slash.js</span>
                        <v-btn class="mx-2" icon text large @click="cd.dropslash = !cd.dropslash">
                            <v-icon color="white">{{icondd}}</v-icon>
                        </v-btn>
                        <div v-if="cd.dropslash">
                            <br>
                            <p>
                                <img class="flex" :src="cd.slash" />
                            </p>
                        </div>
                    </span>
                    <br><v-btn solo elevation="0" color="#fafa52" class="mt-4 custombtn" @click="cd.drop = !cd.drop"><b>{{cd.drop ? 'Hide' : 'Show'}} Code</b></v-btn>
                    <pre v-if="cd.drop" class="precmd prettyprint container bg-light mb-8">
                        {{codesen[i]}}
                    </pre>
                </li>
            </ul>
            <span class="mt-8">Important: This commands list will be updated periodically.</span>
        </span>
    </div>
</template>

<script>

export default {
    name: 'Commands',
    data: () => {
        return {
            drop: false,
            image: [
                "https://i.imgur.com/P4EjxO5.png",
                "https://i.imgur.com/3zWvFpP.png"
            ],
            commands: [{
                name: 'ping',
                info: 'Comando de teste.',
                category: 'Teste',
                drop: false,
                slash: "",
                dropslash: false
            },{
                name: 'info',
                info: "Obter informações de algum comando.",
                category: "Informação",
                drop: false,
                slash: "https://i.imgur.com/6Um1f7D.png",
                dropslash: false
            }],
            commandsen: [{
                name: 'ping',
                info: 'Test Command.',
                category: 'Test',
                drop: false,
                slash: "",
                dropslash: false
            },{
                name: 'info',
                info: "Get information about some command.",
                category: "Information",
                drop: false,
                slash: "https://i.imgur.com/FanOybE.png",
                dropslash: false
            }],
            codes: [`
const Discord = require('discord.js');

module.exports = class ping {
  constructor () {
      this.name = 'ping',
      this.alias = []
  }
  async run (bot, interaction) {
    try {
      await interaction.reply("Pong!");
    } catch (err) {
      throw err;
    }
  }

  help () {
    return {
      name: "ping",
      info: "Comando de teste.",
      category: "fun" 
    }
  }
}
`,
`
const Discord = require('discord.js');
const { CommandHandler } = require ('../CommandHandler.js');

module.exports = class info {
  constructor () {
      this.name = 'info',
      this.alias = []
  }
  async run (bot, interaction) {
    try {
      const handler = new CommandHandler({
          folder: __dirname + '/',
          prefix: ['']
      });
      
      let command = interaction.options.getString('comando');
      let cmd = handler.getCommand(command);
    
      if (cmd) {
        var info = cmd.help();
        await interaction.reply(\`\\\`\${info.name}:\\\` \${info.info}\`);
      }else {
        await interaction.reply({ content: \`\\\`\${command}\\\` não é um comando.\`, ephemeral: true });
      }
    } catch (err) {
      throw err;
    }
  }

  help () {
    return {
      name: "info",
      info: "Comando para obter informações de outros comandos.",
      category: "info" 
    }
  }
}`],
codesen: [`
const Discord = require('discord.js');

module.exports = class ping {
  constructor () {
      this.name = 'ping',
      this.alias = []
  }
  async run (bot, interaction) {
    try {
      await interaction.reply("Pong!");
    } catch (err) {
      throw err;
    }
  }

  help () {
    return {
      name: "ping",
      info: "Test Command.",
      category: "fun" 
    }
  }
}
`,
`
const Discord = require('discord.js');
const { CommandHandler } = require ('../CommandHandler.js');

module.exports = class info {
  constructor () {
      this.name = 'info',
      this.alias = []
  }
  async run (bot, interaction) {
    try {
      const handler = new CommandHandler({
          folder: __dirname + '/',
          prefix: ['']
      });
      
      let command = interaction.options.getString('command');
      let cmd = handler.getCommand(command);
    
      if (cmd) {
        var info = cmd.help();
        await interaction.reply(\`\\\`\${info.name}:\\\` \${info.info}\`);
      }else {
        await interaction.reply({ content: \`\\\`\${command}\\\` is not a command.\`, ephemeral: true });
      }
    } catch (err) {
      throw err;
    }
  }

  help () {
    return {
      name: "info",
      info: "Command to obtain more information about some command.",
      category: "info" 
    }
  }
}`]
        }
    },
}
</script>

<style>
    .text-cmd {
        color: #fafa52 !important;
    }
    .custombtn {
        border-radius: 0.2rem 0.2rem 0 0;
    }
    .precmd {
        max-height: 500px;
        border-left: 10px solid #fafa52;
        border-radius: 0 0 0 .2rem;
    }
</style>