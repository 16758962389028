<template>
    <div class="text-center text-white">
        <h1 class="mt-4 text-4xl">{{$lang === 'pt' ? 'Comandos do Hells Game' : 'Hells Game Commands'}}</h1>
        <div class="d-flex justify-center">
            <hr class="bg-white mr-4 lh my-auto" width="15%">
            <v-icon :class="`mdi mdi-${icon} text-4xl`"></v-icon>
            <hr class="bg-white ml-4 lh my-auto" width="15%">
        </div>
        <div class="justify-center mt-6">
            <v-img :src="$lang == 'pt' ? getImage('hgCmd') : getImage('hgCmdEN')" width="100%" height="100%" class="bordercrs" />
            <div :class="['mt-4 text-white title', $vuetify.display.mobile ? 'mx-2' : 'mx-16 px-6' ]">
                <v-divider class="mt-8" />
                <span class="mt-8 text-center">
                    <h2>{{$lang == 'pt' ? 'Comandos exclusivos para MODERADORES' : 'Exclusive commands to MODS'}}</h2>
                </span>
                <div v-if="$lang == 'pt'">
                    <div class="text-left mt-4" v-for="(cmdm, i) in mods" :key="i+1000">
                        <v-divider />
                        <h3><span class="text-primary">></span> {{cmdm.nome}}</h3>
                        <h5><p v-html="prepareInfo(cmdm.info)" /></h5>
                    </div>
                </div>
                <div v-else>
                    <div class="text-left mt-4" v-for="(cmdm, i) in modsEN" :key="i+1000">
                        <v-divider />
                        <h3><span class="text-primary">></span> {{cmdm.nome}}</h3>
                        <h5><p v-html="prepareInfo(cmdm.info)" /></h5>
                    </div>
                </div>
                <v-divider />
                <v-divider class="mt-16 mb-4" />
                <span class="mt-16 text-center">
                    <h2>{{$lang == 'pt' ? 'Comandos livres' : 'Commands free'}}</h2>
                </span>
                <div v-if="$lang == 'pt'">
                    <div class="text-left mt-4" v-for="(cmd, i) in commands" :key="i">
                        <v-divider />
                        <h3><span class="text-primary">></span> {{cmd.nome}}</h3>
                        <h5><p v-html="prepareInfo(cmd.info)" /></h5>
                    </div>
                </div>
                <div v-else>
                    <div class="text-left mt-4" v-for="(cmd, i) in commandsEN" :key="i">
                        <v-divider />
                        <h3><span class="text-primary">></span> {{cmd.nome}}</h3>
                        <h5><p v-html="prepareInfo(cmd.info)" /></h5>
                    </div>
                </div>
                <v-divider />
            </div>
            <div class="text-center my-16">
                <v-btn color="white" variant="outlined" elevation="2" size="large" href="https://discordapp.com/oauth2/authorize?client_id=472158887966015508&scope=applications.commands%20bot&permissions=242733149249">
                    <v-icon :class="`mdi mdi-${icon} mr-2`"></v-icon>
                    <span v-if="$lang == 'pt'">Adicione ao seu servidor!</span>
                    <span v-else>Add to your guild!</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    Name: "CommandsHG",
    data: () => ({
        icon: 'fire',
        commands: [],
        commandsEN: [],
        mods: [],
        modsEN: []
    }),
    props: {
        lg: String
    },
    methods: {
        getImage(pic) {
            var images = require.context('@/assets/images/banner/', false, /\.png$/);
            return images('./' + pic + '.png')
        },
        getCommands() {
            this.$api.get("/commands/hg").then(res => {
                this.commands = res.data.commands.hg;
                this.mods = res.data.commands.mods;
                this.commandsEN = res.data.commandsEN.hg;
                this.modsEN = res.data.commandsEN.mods;
            }).catch(() => {});
        },
        prepareInfo(str) {
            str = str.split("`e!").join("`/");
            var i = 1;
            while (str.includes('**')) {
                if (i%2 == 1) str = str.replace("**", '<span class="text-warning">');
                else str = str.replace("**", '</span>');
                i++;
            }
            i = 1;
            while (str.includes('`')) {
                if (i%2 == 1) str = str.replace("`", `<span style="color: #ae1e2d !important;">`);
                else str = str.replace("`", '</span>');
                i++
            }
            i = 1;
            while (str.includes('~~')) {
                if (i%2 == 1) str = str.replace("~~", `<span style="color: #888 !important;">`);
                else str = str.replace("~~", '</span>');
                i++
            }
            return str.startsWith("\n") ? str.replace("\n", "").split("\n").join("<br>") : str.split("\n").join("<br>");
        }
    },
    mounted() {
        this.getCommands();
    }
}
</script>

<style>
h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h4 {
    text-align: justify;
    font-size: 1.2rem;
    line-height: 0.75rem;
}
</style>