<template>
    <div class="text-center text-white">
        <h1 class="mt-4 text-4xl">{{$lang === 'pt' ? 'Cartas Contra o Inferno' : 'Cards Against Hell'}}</h1>
        <div class="d-flex justify-center">
            <hr class="bg-white mr-4 lh my-auto" width="15%">
            <v-icon :class="`mdi mdi-${icon} text-4xl`"></v-icon>
            <hr class="bg-white ml-4 lh my-auto" width="15%">
        </div>
        <div class="justify-center mt-6">
            <v-img :src="$lang === 'pt' ? getImage('cardbanner') : getImage('cardbannerEN')" width="100%" height="100%" class="bordercrs" />
            <v-chip size="large" class="mt-6 px-6 text-white pointer lightred">
                <strong v-if="$lang == 'pt'">Em Manutenção</strong>
                <strong v-else>Under Maintenance</strong>
            </v-chip> 
            <div v-if="$lang === 'pt'" class="mt-4 mx-4 text-left text-light title">
                <v-divider />
                <h2><p>O que é o game Cartas Contra o Inferno</p></h2>
                <h4><p>Cartas Contra o Inferno é um jogo de palavras dinâmico baseado no popular game Cartas Contra a Humanidade. Nossa versão é portável para ser jogada em seu servidor Discord com um grupo de 4 a 7 pessoas (você e mais 3~6 amigos ou inimigos). Recomendamos jogar em um canal de voz para uma melhor imersão no game.</p></h4>
                <br />
                <v-divider />
                <h2><p>Cartas Vermelhas</p></h2>
                <h4><p>As Cartas Vermelhas são as cartas mestre da partida. A cada rodada o bot Erebus enviará no chat uma carta vermelha que conterá uma frase com uma lacuna que deve ser preenchida pelos players com suas cartas de jogador.</p></h4>
                <br />
                <v-divider />
                <h2><p>Cartas Roxas</p></h2>
                <h4><p>As Cartas Roxas são as cartas do jogador. No começo da partida o bot enviará no privado do membro cartas que servirão para preencher as lacunas das Cartas Vermelhas e completar as frases. Os usuários terão tempo para escolher uma das Cartas Rochas ou então o bot escolherá pelo usuário e uma vez escolhida uma das cartas ela sumirá das opções de escolha do jogador e uma nova carta estará disponível para a próxima rodada.</p></h4>
                <br />
                <v-divider />
                <h2><p>Votações das Rodadas</p></h2>
                <h4><p>No início de cada rodada haverá um juiz que será indicado pelo Bot. Quando todos os jogadores de uma rodada escolherem suas cartas, o bot enviará as respostas no chat ocultando seus autores. As respostas deverão ser lidas de forma que preencham a lacuna na carta vermelha, então o juiz da rodada deverá escolher a melhor resposta, sendo aquela que melhor encaixou na frase de forma engraçada. O bot indicará qual usuário que deu a resposta escolhida pelo juiz e o jogador que teve sua resposta escolhida ganhará 1 ponto.</p></h4>
                <br />
                <v-divider />
                <h2><p>Iniciando uma Partida</p></h2>
                <h4><p>Para iniciar uma partida, um dos jogadores deve executar o comando "/cards" informando quantos pontos a partida terá e os jogadores que irão participar, excluindo a sí mesmo. Veja os comandos para melhores informações.</p></h4>
                <br />
                <v-divider />
                <h2><p>Finalização de uma Partida</p></h2>
                <h4><p>Ao decorrer das rodadas os jogadores acumularão pontos. O jogador que alcançar o máximo de pontos da rodada antes de todos ganha a partida. Vale ressaltar que o número de pontos para vencer uma partida será indicado pelo usuário que criou a partida, podendo variar entre 4, 6 ou 8 pontos.</p></h4>
                <br />
                <v-divider />
            </div>
            <div v-else class="mt-4 mx-4 text-left text-light title">
                <v-divider />
                <h2><p>What is Cards Against Hell</p></h2>
                <h4><p>Cards Against Hell is a dynamic word game based on the popular game Cards Against Humanity. Our version is portable to be played on your Discord server with a group of 4 to 7 people (you plus 3~6 friends or enemies). We recommend playing on a voice channel for a better immersion in the game. </p></h4>
                <br />
                <v-divider />
                <h2><p>Red Cards</p></h2>
                <h4><p>The Red Cards are the master cards of the game. At each round, the Erebus bot will send a red letter in the chat that will contain a sentence with a gap that must be filled by players with their player cards. </p></h4>
                <br />
                <v-divider />
                <h2><p>Purple Cards</p></h2>
                <h4><p>The Purple Cards are the player's cards. At the beginning of the game, the bot will send letters to the member's private that will serve to fill in the gaps in the Red Cards and complete the sentences. Users will have time to choose one of the Rock Cards or else the bot will choose by the user and once chosen one of the cards it will disappear from the player's choice options and a new card will be available for the next round. </p></h4>
                <br />
                <v-divider />
                <h2><p>Rounds Voting</p></h2>
                <h4><p>At the beginning of each round there will be a judge who will be appointed by the Bot. When all players of a round have chosen their cards, the bot will send the answers in the chat hiding their authors. The answers must be read in such a way that they fill in the gap on the red card, then the judge of the round must choose the best answer, being the one that best fits the sentence in a funny way. The bot will indicate which user gave the answer chosen by the judge and the player who had his answer chosen will earn 1 point. </p></h4>
                <br />
                <v-divider />
                <h2><p>Starting a Match</p></h2>
                <h4><p>To start a game, one of the players must execute the command "/cards" informing how many points the game will have and the players that will participate, excluding himself. See commands for more information. </p></h4>
                <br />
                <v-divider />
                <h2><p>Ending a Match</p></h2>
                <h4><p>As the rounds progress, players will accumulate points. The player who reaches the most points for the round before everyone else wins the game. It is worth mentioning that the number of points to win a match will be indicated by the user who created the match, and may vary between 4, 6 or 8 points. </p></h4>
                <br />
                <v-divider />
            </div>
            <div class="text-center mt-12">
                <v-btn color="white" variant="outlined" size="large" elevation="2" @click="$router.push('/commands/cards')">
                    <v-icon :class="`mdi mdi-${icon} mr-2`"></v-icon>
                    {{$lang === 'pt' ? 'Comandos' : 'Commands'}}
                </v-btn>
                <br />
                <v-btn class="mt-4 mb-12" color="white" variant="outlined" elevation="2" size="large" href="https://discordapp.com/oauth2/authorize?client_id=472158887966015508&scope=applications.commands%20bot&permissions=242733149249">
                    <v-icon :class="`mdi mdi-${icon} mr-2`"></v-icon>
                    <span v-if="$lang == 'pt'">Jogue Agora Mesmo!</span>
                    <span v-else>Play Right Now!</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    Name: "Cards",
    data: () => ({
        icon: 'cards'
    }),
    methods: {
        getImage(pic) {
            var images = require.context('@/assets/images/banner/', false, /\.png$/);
            return images('./' + pic + '.png')
        },
    },
    mounted() {
    }
}
</script>

<style>
h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
h4 {
    text-align: justify;
    font-size: 1.2rem;
    line-height: 0.75rem;
}
</style>