<template>
    <div>
        <v-navigation-drawer class="cprimary text-white" v-model="sidebar" app temporary>
            <h1 class="mt-4 ml-2 text-2xl">Erebus</h1>
            <ul class="px-4 py-4">
                <li v-for="item in menu"
                    :key="item.title"
                    :class="`px-3 mx-auto py-1 my-auto cursor-pointer d-flex text-lg hover:navselected ${item.select.includes($route.fullPath) ? 'navselected' : ''}`"
                    @click="$router.push(item.path)">
                    <v-icon :class="`mdi mdi-${item.icon} mr-1 my-auto`" /> <h1 class="my-auto">{{item.title}}</h1>
                </li>
            </ul>
        </v-navigation-drawer>
        <v-app-bar class="cprimary text-white pr-2">
            <v-app-bar-title class="mr-4 max-w-fit">
                <v-img src="https://i.imgur.com/grbjw2K.png" width="50" height="50" class="cursor-pointer" @click="$router.push('/')" />
            </v-app-bar-title>
            <ul v-if="!$vuetify.display.mobile" class="d-flex">
                <li v-for="(item, index) in menu" :key="index"
                    @click="$router.push(item.path)"
                    :class="`px-3 mx-1 py-1 my-auto cursor-pointer d-flex text-lg hover:border-b-2 ${item.select.includes($route.fullPath) ? 'border-b-2' : ''}`">
                    <v-icon :class="`mdi mdi-${item.icon} mr-1 my-auto`" /> <h1 class="my-auto">{{item.title}}</h1>
                </li>
            </ul>
            <v-spacer />
            <v-btn v-if="$vuetify.display.mobile" text icon large class="ml-2" @click="sidebar = !sidebar">
                <v-icon color="white" class="mdi mdi-menu" />
            </v-btn>
            <div v-if="user">
                <v-menu location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-avatar v-bind="props" size="56"
                         :image="'https://cdn.discordapp.com/avatars/' + user.uid + '/' + user.avatar + '.png'"
                          class="borderav cursor-pointer" />
                    </template>
                    <v-list class="lightred text-white w-52">
                        <v-list-item link>
                            <v-list-item-title @click="logout()">Sair</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-else>
                <v-btn variant="outlined" size="large" color="white" class='text-white' :href="geturl()">
                    <h1>Login</h1>
                </v-btn>
            </div>
        </v-app-bar>
    </div>
</template>

<style>
.navselected {
    background-color: rgba(255, 255, 255, 1);
    color: var(--primary) !important;
}
</style>

<script>
import { mdiHome } from '@mdi/js';
// import { mdiCog } from '@mdi/js';
import { mdiFire } from '@mdi/js';
import { mdiCards } from '@mdi/js';
import { mdiSwordCross } from '@mdi/js';
import { mdiWeb } from '@mdi/js';
// import { mdiTreasureChest } from '@mdi/js';
// import { mdiYoutube } from '@mdi/js';

export default {
  name: 'Navbar',
  data: () => ({
    user: null,
    globe: mdiWeb,
    sidemenu: false,
    selectedItem: "Home",
    sidebar: false,
    menuPT: [
    {
        title: "Home",
        path: "/",
        disabled: false,
        icon: 'home',
        select: ["/"]
    },
    // {
    //     title: "Comandos",
    //     path: "/commands",
    //     disabled: false,
    //     icon: mdiCog
    // },
    {
        title: "Hells Game",
        path: "/hellsgame",
        disabled: false,
        icon: 'fire',
        select: ["/hellsgame", "/commands/hg"]
    }, {
        title: "Cartas",
        path: "/cards",
        disabled: false,
        icon: 'cards',
        select: ["/cards", "/cartas", "/commands/cards"]
    },
    // {
    //     title: "Baú do Tesouro",
    //     path: "/chestloot",
    //     icon: mdiTreasureChest
    // },
    {
        title: "RPG",
        path: "/rpg",
        disabled: false,
        icon: 'sword-cross',
        select: ["/rpg"]
    }
    ],
    menuEN: [{
        title: "Home",
        path: "/",
        disabled: false,
        icon: mdiHome
    },
    // {
    //     title: "Commands",
    //     path: "/commands",
    //     disabled: false,
    //     icon: mdiCog
    // },
    {
        title: "Hells Game",
        path: "/hellsgame",
        disabled: false,
        icon: mdiFire
    }, {
        title: "Cards",
        path: "/cards",
        disabled: false,
        icon: mdiCards
    },
    // {
    //     title: "Chest Loot",
    //     path: "/chestloot",
    //     icon: mdiTreasureChest
    // }
    {
        title: "RPG",
        path: "/rpg",
        disabled: false,
        icon: mdiSwordCross
    }]
  }),
  methods: {
      geturl() {
          return this.$loginURL;
      },
      logout() {
          var body = {token: this.$cookies.get("USER_SESSION_TOKEN")};
          this.$api.post('/logout', body).then(() => {
              this.user = null;
              this.$cookies.remove("USER_SESSION_TOKEN");
          }).catch(err => {
              console.log(err);
          })
      }
  },
  computed: {
      menu: function () {
          return this.menuPT;
      }
  },
  async beforeMount() {
      await this.$timer.wait(0.3);
      var cookie = this.$cookies.get("USER_SESSION_TOKEN");
      if (cookie) {
          this.$api.defaults.headers.common['Authorization'] = `Bearer ${cookie}`;
          this.$api.get('/getUser').then(res => {
              if (res.data === "Usuário não encontrado.") this.$cookies.remove("USER_SESSION_TOKEN");
              else {
                  this.user = res.data;
                  this.$emit('setUser', this.user);
              }
          }).catch(err => {
              this.$cookies.remove("USER_SESSION_TOKEN");
              console.log(err);
          })
      }
  }
};
</script>