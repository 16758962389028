<template>
    <div class="mt-4 text-center">
        <h1 class="text-white mt-4 mb-6 text-5xl">{{$lang === 'pt' ? 'Esta página está em manutenção.' : 'This page is under maintenance.'}}</h1>
        <center><v-img src="https://i.imgur.com/xw1B5uB.gif" width="50%" height="50%" class="mt-6" /></center>
    </div>
</template>

<script>
  export default {
    name: 'Manutencao',
    data: () => ({
      lang: 'pt'
    }),
    mounted() {
    }
  }
</script>