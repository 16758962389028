<template>
  <v-app class="background">
    <Navbar />
    <v-main>
      <div :class="['panel', $vuetify.display.mobile ? 'w-full' : 'w70']">
        <router-view/>
      </div>
    </v-main>
    <Footer :class="$vuetify.display.mobile ? 'pt-36' : ''" />
  </v-app>
</template>
<script>

// Components
import Footer from './components/footer.vue';
import Navbar from './components/navbar.vue';

export default {
  name: 'App',
  components: {
    Footer,
    Navbar
  },
  data: () => ({
    //
  }),
}
</script>
<style>
@import url('assets/styles.css');

:root {
  --primary: #8c0f06;
  --lightred: #AE1E2D;
}

p {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.cprimary {
  background-color: var(--primary) !important;
}

.lightred {
  background-color: var(--lightred) !important;
}

.w70 {
  width: 70%;
}

.panel {
  background-color: #343a40;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 3%;
  padding-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  height: 100%;
}

.background {
  background-image: url("assets/bgimg.png") !important;
  background-repeat: repeat;
}
</style>
