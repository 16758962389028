<template>
    <div>
        <span v-if="$lang === 'pt'">Insira o código abaixo no arquivo <span clas="text-index">index.js</span>.</span>
        <span v-else>Paste the code bellow in the file <span clas="text-index">index.js</span>.</span>
        <pre class="preslash bg-white my-8 px-2 overflow-auto">
            {{$lang === 'pt' ? code : codeen}}
        </pre>
        <span v-if="$lang === 'pt'">Agora crie uma pasta chamada <span clas="text-index">commands</span> (ao lado do botão de adicionar arquivo) e adicione os comandos que desejar.<br>Depois de ter configurado seus comandos, basta executar a aplicação clicando no botão <v-btn solo color="#009118" class="softround"><span class="text-btn-index"><i class="fa-solid fa-play"></i> Run</span></v-btn> localizado no centro da barra superior.</span>
        <span v-else>Now, create a folder named <span clas="text-index">commands</span> (next to the button to add file) and add the commands that you desire.<br>After configuring your commands, you can just run your project by clicking in the button <v-btn solo color="#009118" class="softround"><span class="text-btn-index"><i class="fa-solid fa-play"></i> Run</span></v-btn> located at the center of the top bar.</span>
    </div>
</template>

<script>
export default {
    name: 'IndexCode',
    data: () => {
        return {
            code: `
const Discord = require ('discord.js');
const iFlags = Discord.Intents.FLAGS;
const bot = new Discord.Client({ intents: [iFlags.GUILDS, iFlags.GUILD_MESSAGE_REACTIONS, iFlags.GUILD_MESSAGE_TYPING], disableEveryone: true, partials: ['MESSAGE', 'CHANNEL', 'REACTION'] });
const { CommandHandler } = require ('./CommandHandler.js');

let cooldown = new Set();

const CH = new CommandHandler({
    folder: __dirname + '/commands/', // Diretório da pasta com seus comandos
    prefix: [''] // Caso não utilize slash commands, coloque o prefix do seu bot aqui.
});

bot.on("ready", () => {
  console.log("Estou vivo!");
});

bot.on("interactionCreate", async interaction => {
  try {
    // Verifica se a interação é um slash command. Caso não seja, não executa nada
    if (!interaction.isCommand()) return;

    var sender = await interaction.user;
    // Pegando o nome do comando executado
    let cmdn = await interaction.commandName;
    // Procurando o comando pelo Command Handler
    let cmd = CH.getCommand(cmdn);
    // Cooldown para executar um comando
    const cdseconds = 5; // Tempo para o cooldown em segundos
    if (cooldown.has(sender.id)) {
      await interaction.reply({ content: \`Você deve aguardar \${cdseconds} segundos para utilizar outro comando!\`, ephemeral: true});
      return;
    }
    cooldown.add(sender.id);
    setTimeout(() => {
      cooldown.delete(sender.id);
		}, cdseconds*1000);

    // Executando o comando pelo Command Handler
    try {
			await cmd.run(bot, interaction);
    }catch (err) {
      console.error(\`Erro ao executar o comando \${cmdn}: \` + err.message);
      if (interaction.deferred || interaction.replied)
        await interaction.editReply({content: "Um erro ocorreu ao processar este comando. Tente novamente.", ephemeral: true, components: []}).catch(err => {});
      else
        await interaction.reply({content: "Um erro ocorreu ao processar este comando. Tente novamente.", ephemeral: true}).catch(err => {});
    }
  } catch (err) {
    console.error("Um erro ocorreu com a interação: " + err.message);
  }
});

bot.login(process.env.TOKEN).catch(err => console.error("error login.\n" + err.message));`,
codeen: `
const Discord = require ('discord.js');
const iFlags = Discord.Intents.FLAGS;
const bot = new Discord.Client({ intents: [iFlags.GUILDS, iFlags.GUILD_MESSAGE_REACTIONS, iFlags.GUILD_MESSAGE_TYPING], disableEveryone: true, partials: ['MESSAGE', 'CHANNEL', 'REACTION'] });
const { CommandHandler } = require ('./CommandHandler.js');

let cooldown = new Set();

const CH = new CommandHandler({
    folder: __dirname + '/commands/', // Folder directory of your commands
    prefix: [''] // If you don't use slash commands, put your prefix here
});

bot.on("ready", () => {
  console.log("Estou vivo!");
});

bot.on("interactionCreate", async interaction => {
  try {
    // If the interaction is not a command, don't execute the code
    if (!interaction.isCommand()) return;

    var sender = await interaction.user;
    // Catching the name of the command
    let cmdn = await interaction.commandName;
    // Searching the command through Command Handler
    let cmd = CH.getCommand(cmdn);
    // Cooldown to use commands
    const cdseconds = 5; // Cooldown time in seconds
    if (cooldown.has(sender.id)) {
      await interaction.reply({ content: \`You must wait \${cdseconds} seconds to use another command!\`, ephemeral: true});
      return;
    }
    cooldown.add(sender.id);
    setTimeout(() => {
      cooldown.delete(sender.id);
		}, cdseconds*1000);

    // Execute the command with Command Handler
    try {
			await cmd.run(bot, interaction);
    }catch (err) {
      console.error(\`Error executing the command \${cmdn}: \` + err.message);
      if (interaction.deferred || interaction.replied)
        await interaction.editReply({content: "An error has ocurred processing this command. Try again.", ephemeral: true, components: []}).catch(err => {});
      else
        await interaction.reply({content: "An error has ocurred processing this command. Try again.", ephemeral: true}).catch(err => {});
    }
  } catch (err) {
    console.error("An error has ocurred with this interaction: " + err.message);
  }
});

bot.login(process.env.TOKEN).catch(err => console.error("error login.\n" + err.message)); `
        }
    },
}
</script>

<style>
    .text-btn-index {
        color: #fff;
    }
    .softround {
        border-radius: 0.5rem;
    }
    .text-index {
        color: #10b7e6 !important;
    }
    .preindex {
        max-height: 500px;
        border-left: 10px solid #10b7e6;
;
        border-radius: 0.2rem;
    }
</style>