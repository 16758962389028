// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
        light: {
            primary: '#8c0f06',
            lightred: '#AE1E2D',
        },
        dark: {
            primary: '#8c0f06',
            lightred: '#AE1E2D',
        }
    }
  }
})
