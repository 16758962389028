<template>
  <div style="margin-left: 5%; height:100%; padding:1%;">
      <div class="text-white mt-16 mb-8">
        <center>
            <h1 class="text-4xl">{{$lang == 'pt' ? "Você não está logado." : "You're not logged in."}}</h1>
            <v-img class="mt-8 flex" src="https://i.imgur.com/ArYBG32.png" width="auto" height="50%" />
        </center>
      </div>
  </div>
</template>

<script>
export default {
  name: "NotLogged",
  data: () => ({
  }),
  mounted() {
  }
}
</script>