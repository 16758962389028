<template>
  <div>
    <Carousel />
    <hr class="my-4 lh">
    <section id="Erebus">
      <div class="text-white py-4">
        <center>
          <h1 class="text-4xl">Erebus</h1>
          <div class="d-flex justify-center">
            <hr class="text-white bg-white mr-4 lh my-auto" width="15%">
            <v-icon large class="fas fa-robot text-red-400"></v-icon>
            <hr class="text-white bg-white ml-4 lh my-auto" width="15%">
          </div>
        </center>
        <div :class="$vuetify.display.mobile ? 'text-xl mt-4' : 'd-flex text-xl mt-4'">
          <div :class="$vuetify.display.mobile ? 'text-justify' : 'col-span-4 text-right mr-4'">
            <h5 v-if="$lang== 'pt'">Erebus é um bot de diversão para o seu servidor Discord. Adicione-o ao seu servidor e promova mais interação entre seus membros e, consequentemente, tenha membros mais engajados e ativos no seu servidor. O Hells Game é perfeito para dar uma animada no servidor.</h5>
            <h5 v-else>Erebus is a bot of fun to your guild. Add him to your server and promote more interaction between your members and, consequently, have more engajed and actives members in your guild. Hells Games is perfect to liven things up in your server.</h5>
          </div>
          <div :class="$vuetify.display.mobile ? 'text-justify pt-2' : 'col-span-4 text-left ml-4'">
            <h5 v-if="$lang== 'pt'">Os comandos do bot Erebus são focados em diversão, sendo assim ele não tem permissões administrativas e é 100% confiável para o seu servidor Discord. Se tiver alguma dúvida, entre em contato com a gente no servidor Discord Ice Cream Kingdom.</h5>
            <h5 v-else>The commands of the bot are focused in fun, therefore he doesn't have any administrative permissions and its 100% reliable to your guild.</h5>
          </div>
        </div>
        <div class="text-center mt-4">
            <v-btn color="white" variant="outlined" elevation="2" size="large" href="https://discordapp.com/oauth2/authorize?client_id=472158887966015508&scope=applications.commands%20bot&permissions=242733149249">
              <v-icon class="fas fa-robot mr-2"></v-icon><span v-if="$lang== 'pt'">Adicione-o ao seu Servidor!</span>
              <span v-else>Add to your guild</span>
            </v-btn>
        </div>
        <div class="text-center mt-4 mb-4">
          <v-dialog max-width="800" v-model="dialog">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" color="white" elevation="2" size="large">
                  <v-icon class="fas fa-robot mr-2"></v-icon><span v-if="$lang== 'pt'">História do Erebus</span>
                  <span v-else>Erebus History</span>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar class="cprimary d-flex py-2 px-4">
                <h4 class="text-3xl text-white"><strong class="times" v-if="$lang=== 'pt'">Prólogo do Inferno</strong><strong v-else class="times">Hells Prologue.</strong></h4>
                <v-spacer />
                <v-btn
                  class="text-white"
                  color="white"
                  icon
                  large
                  @click="dialog = false">
                  <v-icon class="fa-solid fa-xmark" />
                </v-btn>
              </v-toolbar>
              <v-card-text v-if="$lang== 'pt'">
                <h6 class="text-black text-justify times">
                <p class="my-4">Na mitologia grega, Erebus é a personificação das trevas e da escuridão. Tem seus domínios demarcados por seus mantos escuros e sem vida, predominando sobre as regiões do espaço conhecidas como Vácuo logo acima dos mantos noturnos de sua irmã Nix, a personificação da noite. Erebus é filho de Caos. Erebus e sua irmã gêmea Nix, nasceram de cisões assim como se reproduzem os seres unicelulares; a partir de partes de Caos, Erebu e Nix passam a ser os mais velhos imortais do universo, logo após seu pai.</p>
                <p class="my-4">Erebus desposou Nix, gerando dois deuses primordiais: o Éter (a luz celestial) e Hemera (o Dia). Assim como a irmã, era capaz de tirar a imortalidade dos deuses. Erebus é o próprio universo, senhor dos cosmos e dos buracos negros. Hoje, entretanto, é uma potência esquecida. Está encarcerado no Tártado. No passado, pretendia liberar, sozinho, os titãs aprisionados pelos olimpianos logo após a Titanomaquia, entretanto caiu em uma emboscada armada pela irmã. Zeus, Hades e Nix, tementes do poder do grande deus primordial e do possível retorno dos titãs, o impediram; os três, unidos, cada um se valendo de seus poderes únicos, jogaram Erebus no rio infernal Aqueronte, o rio da morte. Depois, encaminharam o corpo fragilizado do inimigo para o Tártaro, única prisão capaz de detê-lo.</p>
                <p class="my-4">Erebus é conhecido por ser um dos maiores inimigos de Zeus. Conta-se que os titãs pediram socorro a Erebus e pessoalmente o primórdio desceu até o Tártaro para libertar os filhos de Gaia, porém foi surpreendido por Zeus e Hades que tiveram a ajuda de Nix para lançar Erebus nas profundezas do rio Aqueronte, a fronteira dos dois mundos. À medida que o pensamento mítico dos gregos se desenvolveu, Erebus deu seu nome a uma região do Hades, por onde os mortos tinham de passar imediatamente depois da morte para entrar no submundo. Após Caronte tê-los feito atravessar o rio Aqueronte, entravam o Tártaro, o submundo propriamente dito.</p>
                <p class="my-4">Séculos se passaram, as coisas no olimpo estavam indo de mal a pior, Zeus estava prestes a perder na guerra contra Hades até que Nix sugeriu que Zeus recorresse à Erebus para destronar Hades e ficar no controle do inferno. E assim Zeus aceitou os conselhos de Nix e foi até o Tártaro para libertar Erebus. Ainda fraco, Erebus retornou do submundo e, com sede de vingança, decidiu se livrar de hades primeiro, aceitando a proposta feita por Zeus e Nix.</p>
                <p class="my-4">A guerra durou 3 décadas. Hades enviava demônios todo o tempo, mas Erebus sempre os controlava trazendo-os para seu lado. Quando Erebus finalmente se encontrou cara a cara com Hades, Erebus fez com que Hades se tornasse mortal, afinal Erebus e sua irmã Nix eram capazes de tirar a imortalidade de deuses. Uma vez que Hades se tornou mortal, Erebus deixou que Zeus desse o último golpe para matar Hades. E assim Zeus o fez. Logo após Zeus apunhalar Hades, Erebus imediatamente retirou a imortalidade de Zeus, o matou imediatamente e quando fora atrás de Nix, ela já tinha fugido dali e se escondeu no mundo humado. Após séculos procurando por sua irmã Nix, Erebus chegou ao mundo humano e achou Nix juntamente com Atena, porém elas não eram páreo para o imenso poder de Erebus. Nix morrera junto com Atena e seus cavaleiros.</p>
                </h6>
              </v-card-text>
              <v-card-text v-else>
                <h6 class="text-dark text-justify times">
                <p class="my-4">In Greek mythology, Erebus is the personification of darkness. He has his domains demarcated by his dark and lifeless cloaks, predominating over the regions of space known as Vacuum just above the nocturnal cloaks of his sister Nix, the personification of night. Erebus is the son of Chaos. Erebus and his twin sister Nix were born from splits just as unicellular beings reproduce; from parts of Chaos, Erebus and Nix become the oldest immortals in the universe, right after their father.</p>
                <p class="my-4">Erebus married Nix, begetting two primordial gods: the Ether (the celestial light) and Hemera (the Day). Like her sister, she was able to take immortality from the gods. Erebus is the universe itself, lord of the cosmos and black holes. Today, however, he is a forgotten power. He is incarcerated in Tartarus. In the past, he intended to free, single-handedly, the Titans imprisoned by the Olympians shortly after Titanomachy, however he fell into an ambush by his sister. Zeus, Hades and Nix, fearful of the power of the great primordial god and the possible return of the titans, prevented it; the three, united, each availing themselves of their unique powers, threw Erebus into the infernal Acheron River, the river of death. Then they sent the weakened body of the enemy to Tartarus, the only prison capable of holding him back.</p>
                <p class="my-4">Erebus is known to be one of Zeus' greatest enemies. It is said that the titans asked Erebus for help and personally the beginning descended to Tartarus to free the children of Gaia, but he was surprised by Zeus and Hades who had the help of Nix to launch Erebus in the depths of the river Acheron, the border of the two worlds. As the mythical thinking of the Greeks developed, Erebus gave his name to a region of Hades, through which the dead had to pass immediately after death to enter the Underworld. After Charon had made them cross the river Acheron, they entered Tartarus, the underworld itself.</p>
                <p class="my-4">Centuries passed, things on Olympus were going from bad to worse, Zeus was about to lose in the war against Hades until Nix suggested that Zeus turn to Erebus to dethrone Hades and take control of hell. And so Zeus accepted Nix's advice and went to Tartarus to free Erebus. Still weak, Erebus returned from the underworld and, thirsting for revenge, decided to get rid of Hades first, accepting the proposal made by Zeus and Nix.</p>
                <p class="my-4">The war lasted 3 decades. Hades sent demons all the time, but Erebus always controlled them by bringing them to his side. When Erebus finally came face to face with Hades, Erebus made Hades become mortal, after all Erebus and his sister Nix were capable of taking immortality from gods. Once Hades became mortal, Erebus let Zeus deliver the last blow to kill Hades. And so Zeus did. Right after Zeus stabbed Hades, Erebus immediately stripped Zeus of immortality, killed him immediately and when he went after Nix, she had already fled from there and hid in the human world. After centuries looking for his sister Nix, Erebus arrived in the human world and found Nix along with Athena, but they were no match for the immense power of Erebus. Nix had died along with Athena and her knights.</p>
                </h6>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </section>
    <hr class="my-4 lh">
    <section id="IceCreamKingdom">
      <div class="text-white py-4">
        <center>
          <h1 class="text-4xl">Ice Cream Kingdom</h1>
          <div class="d-flex justify-center">
            <hr class="text-white bg-white mr-4 lh my-auto" width="15%">
            <v-icon large color="pink" class="fas fa-ice-cream"></v-icon>
            <hr class="text-white bg-white ml-4 lh my-auto" width="15%">
          </div>
        </center>
        <div class="container mt-4">
          <div :class="$vuetify.display.mobile ? 'text-xl' : 'd-flex text-xl'">
            <div class="mx-auto text-center">
                <h5 v-if="$lang === 'pt'">
                  Servidor Encerrado!
                  <br />
                  <v-icon class="mdi mdi-coffin my-auto text-white" icon="mdi-coffin" />
                  20/07/2019 - 16/09/2023
                  <v-icon class="mdi mdi-coffin my-auto text-white" icon="mdi-coffin" />
                </h5>
                <h5 v-else>
                  Server Ended!
                  <br />
                  <v-icon class="mdi mdi-coffin my-auto text-white" icon="mdi-coffin" />
                  20/07/2019 - 16/09/2023
                  <v-icon class="mdi mdi-coffin my-auto text-white" icon="mdi-coffin" />
                </h5>
              </div>
          </div>
      </div>
      </div>
    </section>
    <hr class="my-4 lh">
    <section id="Nordlingen">
      <div class="text-white py-4">
        <center>
          <h1 class="text-4xl">Reino de Nördlingen</h1>
          <div class="d-flex justify-center">
            <hr class="text-white bg-white mr-4 lh my-auto" width="15%">
            <v-icon large color="yellow" class="fas fa-crown"></v-icon>
            <hr class="text-white bg-white ml-4 lh my-auto" width="15%">
          </div>
        </center>
        <div>
          <center>
            <div class="my-4 md:d-flex md:col-span-12 justify-content-start">
              <div class="md:col-span-2 md:mr-6">
                <v-img src="https://i.imgur.com/IQLuYp9.png?1" width="200px" height="auto" display="inline-block" />
              </div>
              <div class="md:col-span-6 text-justify text-lg">
                <h5 v-if="$lang=== 'pt'">Olá Aventureiro, eu me chamo <span class="text-warning">Mortis Album</span> e sou o mago mais poderoso de todo o Reino de Nördlingen. Não se preocupe, eu não sou do tipo que machuca as pessoas, muito pelo contrário, sou eu quem as salva do perigo destas terras longínquas. Você está com sorte Aventureiro, eu estava a procura de um aprendiz e você será perfeito, mas antes de embarcarmos nessa aventura leia este livro sobre o Reino de Nördlingen.</h5>
                <h5 v-else>Hello Adventurous , my name is <span class="text-warning">Mortis Album</span> and i'm the most powerful wizard in the kingdom. Don't worry, i'm not the type that hurts peoples, quite the opposite, i'm the one who save them from danger in this wasty lands. You're lucky Adventurous, i was looking for an apprentice and you'll be perfect, but before embarking in this adventure read this book about the Nördlingen Kingdom.</h5>
              </div>
            </div>
          </center>
        </div>
        <center>
          <v-img v-if="$lang=== 'pt'" :src="require('@/assets/images/book.png')" class="my-4 mx-4" style="width: 70%;" />
          <v-img v-else :src="require('@/assets/images/booken.png')" class="my-4 mx-4" style="width: 70%;" />
        </center>
      </div>
    </section>
  </div>
</template>

<script>
  import Carousel from './Components/bCarousel.vue';
  export default {
    name: 'Home',
    components: {
      Carousel
    },
    data: () => ({
      erebushist: false,
      dialog: false
    }),
    methods: {
        getImage(pic) {
            var images = require.context('@/assets/images/banner/', false, /\.png$/);
            return images('./' + pic + '.png')
        },
    },
    mounted() {
    }
  }
</script>
