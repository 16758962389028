import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './assets/tailwind.css'
import VueCookies from 'vue-cookies'
import axios from 'axios';
import config from '../config.json';
import wait from './wait.js';
import './assets/styles.css';
import Slash from './views/Hyouka/Codes/Slash.vue';
import IndexCode from './views/Hyouka/Codes/IndexCode.vue';
import CommandHandler from './views/Hyouka/Codes/CommandHandler.vue';
import Commands from './views/Hyouka/Codes/Commands.vue';

loadFonts()

const api = axios.create({
  baseURL: `${config.urlapi}`
});
api.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

let app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueCookies)
  .use(wait);
  
app.component("Slash", Slash);
app.component("IndexCode", IndexCode);
app.component("CommandHandler", CommandHandler);
app.component("Commands", Commands);

app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$loginURL = config.login;
app.config.globalProperties.$api = api;
app.config.globalProperties.$timer = wait;
app.config.globalProperties.$lang = "pt";
  
app.mount('#app')
app.$cookies.config('1d');