<template>
<div>
    <v-carousel
    class="bordercrs"
    show-arrows="hover"
    hide-delimiters
    id="carousel-fade"
    touch
    cycle
    :interval="interval"
    width="100%"
    height="auto">
      <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="getImage(slide.srcpt)" cover />
  </v-carousel>
</div>
</template>

<script>
  export default {
    name: 'Home',
    data: () => ({
      interval: 7000,
      slides: [
          {srcen: "carr01en", srcpt: "carr01", path: "#Erebus"},
          {srcen: "carr02en", srcpt: "carr02", path: "/hellsgame"},
          {srcen: "carr03", srcpt: "carr03", path: "#IceCreamKingdom"},
          {srcen: "carr04en", srcpt: "carr04", path: "#Nordlingen"},
          {srcen: "carr05en", srcpt: "carr05", path: "/rpg"},
          //{srcen: "carr06en", srcpt: "carr06", path: '/chestloot'},
          {srcen: "cardsen", srcpt: "cards", path: "/cards"}
      ],
      erebushist: false
    }),
    methods: {
      getImage(pic) {
          if (pic.startsWith('http')) return pic;
          var images = require.context('@/assets/images/banner/', false, /\.png$/);
          return images('./' + pic + '.png')
      },
      goto(url) {
        this.$router.push(url);
      }
    },
    mounted() {
    }
  }
</script>