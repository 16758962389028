<template>
    <div class="text-center text-white mt-16">
        <h1 class="text-5xl">Logging In...</h1>
    </div>
</template>

<script>
export default {
    async beforeMount() {
        var token = this.$route.query.token;
        if (token) {
            await this.$cookies.set("USER_SESSION_TOKEN", token);
            this.$router.push("/");
        }else this.$router.push('/error');
    }
}
</script>