<template>
    <div class="text-center text-white">
        <center>
          <h1 class="text-4xl">Hells Game</h1>
          <div class="d-flex justify-center">
            <hr class="bg-white mr-4 lh my-auto" width="15%">
            <v-icon large :class="`mdi mdi-${icon} text-4xl`"></v-icon>
            <hr class="bg-white ml-4 lh my-auto" width="15%">
          </div>
        </center>
        <div class="justify-content-center mt-6">
            <v-img :src="require('@/assets/images/banner/hgpage.png')" width="100%" height="100%" class="bordercrs" />
            <div v-if="$lang === 'pt'" class="mt-8 text-light text-left title mx-4">
                <v-divider />
                <h2><p>O que é o Hells Game</p></h2>
                <h4><p>Hells Game (HG) é um game narrativo com escolhas dinâmicas que pode ser jogado com em seu servidor Discord. Ao se iniciar uma partida por meio do comando “/hgmod start” o bot Erebus narrará no chat os acontecimentos da partida e por vezes os usuários mencionados nesses acontecimentos precisam reagir na mensagem como forma de escolher rotas a serem seguidas.</p></h4>
                <br />
                <v-divider />
                <h2><p>Comandos para HGMODS</p></h2>
                <h4><p>Comandos restritos a usuários com função de moderadores do Hells Game no servidor Discord ou administrador do servidor. Um usuário administrador pode adicionar novos moderadores por meio do comando “/hgmod mods”. Os Moderadores do HG possuem permissão para: começar, prosseguir, finalizar uma partida, anunciar as mortes na partida, verificar jogadores fora do servidor, forçar a saída de um jogador do HG, renomear o nome de algum jogador, comprar melhorias do HG no servidor, ativar ou desativar a loja do HG, configurar Inscrições por reação e limpar os jogadores do HG no servidor.</p></h4>
                <br />
                <v-divider />
                <h2><p>Hells Game Shop</p></h2>
                <h4><p>A loja Hells Game Shop fornece vantagens a serem obtidas. Algumas dessas vantagens vão desde configurações para o servidor Discord como por exemplo a opção de entrar no HG reagindo numa mensagem ou aumentar a quantidade de partidas semanais, enquanto outras vantagens fornecem benefícios que aumentam a chance de um player vencer uma partida HG.</p></h4>
                <br />
                <v-divider />
                <h2><p>Entrando em uma Partida</p></h2>
                <h4><p>Há duas maneiras dos usuários do servidor entrarem em uma partida HG. A primeira é o usuário player que deseja participar da partida, deve utilizar o comando “/hg join” em algum chat do servidor Discord. A segunda maneira seria um administrador adquirir na loja do HG a função de Inscrições por Reação, caso adquirida, o usuário administrador pode configurá-la por meio do comando “/hgmod rjoin set” com o ID do canal desejado na frente do comando, dessa forma caso um usuário player queira entrar na partida ele deve reagir na mensagem disponível no canal que você forneceu. É importante lembrar de verificar as permissões do bot nesse canal, se ele não possuir alguma permissão base, o bot não será capaz de gerenciar as inscrições por reações.</p></h4>
                <br />
                <v-divider />
                <h2><p>Iniciando uma Partida</p></h2>
                <h4><p>Caso tenha o número mínimo necessário de players para iniciar uma partida, o HGMOD pode utilizar o comando “/hgmod start” no canal que desejar para iniciar a partida, é recomendável criar um canal exclusivo para esse evento. Após iniciar a partida e obter a resposta do bot, o usuário HGMOD deve utilizar o comando “/hgmod next” para dar prosseguimento com a narração do evento até que saia um vencedor ou coisa parecida.</p></h4>
                <br />
                <v-divider />
                <h2><p>Finalizando uma partida</p></h2>
                <h4><p>No fim da partida o HGMOD deve utilizar o comando “/hgmod finish” para indicar ao bot que a partida acabou e ele declarar quem é o vencedor. Finalizado a partida o comando “/hgmod clean” pode ser utilizado para limpar a lista de players e dessa forma os usuários poderão se inscrever novamente para uma nova partida HG.</p></h4>
                <br />
                <v-divider />
            </div>
            <div v-else class="mt-8 text-light text-left title mx-4">
                <v-divider />
                <h2><p>What is Hells Game</p></h2>
                <h4><p>Hells Game (HG) is a narrative game with dynamic choices that can be played with on your Discord server. When starting a match using the command “/hgmod start” the Erebus bot will narrate the events of the match in the chat and sometimes users mentioned in these events need to react in the message as a way of choosing routes to be followed. </p></h4>
                <br />
                <v-divider />
                <h2><p>Commands for HGMODS</p></h2>
                <h4><p>Commands restricted to users with Hells Game moderator role on the Discord server or server administrator. An admin user can add new moderators via the command “/hgmod mods”. HG Moderators are allowed to: start, proceed, end a match, announce in-game kills, check players off the server, force a player out of the HG, rename a player, buy HG upgrades on the server , enable or disable the HG store, configure Reaction Subscriptions, and clear HG players from the server. </p></h4>
                <br />
                <v-divider />
                <h2><p>Hells Game Shop</p></h2>
                <h4><p>The Hells Game Shop store provides perks to obtain. Some of these perks range from Discord server settings such as the option to join HG by reacting to a message or increase the amount of weekly matches, while other perks provide benefits that increase a player's chance of winning an HG match. </p></h4>
                <br />
                <v-divider />
                <h2><p>Joining a Match</p></h2>
                <h4><p>There are two ways for server users to join an HG match. The first is the player user who wants to participate in the match, must use the command “/hg join” in some chat on the Discord server. The second way would be for an administrator to acquire the Reaction Subscriptions function in the HG store, if acquired, the administrator user can configure it through the command “/hgmod rjoin set” with the desired channel ID in front of the command, this way In this way, if a player user wants to join the game, he must react to the message available in the channel you provided. It's important to remember to check the bot's permissions on this channel, if it doesn't have some base permissions, the bot won't be able to manage subscriptions by reactions. </p></h4>
                <br />
                <v-divider />
                <h2><p>Starting a Match</p></h2>
                <h4><p>If you have the minimum number of players required to start a match, HGMOD can use the command “/hgmod start” on the channel you want to start the match, it is recommended to create an exclusive channel for this event. After starting the game and getting the bot's response, the HGMOD user must use the command “/hgmod next” to continue narrating the event until a winner or something similar emerges. </p></h4>
                <br />
                <v-divider />
                <h2><p>Finishing a Match</p></h2>
                <h4><p>At the end of the match the HGMOD must use the command “/hgmod finish” to indicate to the bot that the match is over and it declares who is the winner. At the end of the match the command “/hgmod clean” can be used to clear the list of players and in this way users will be able to register again for a new HG match. </p></h4>
                <br />
                <v-divider />
            </div>
            <div class="text-center mt-12">
                <v-btn color="white" variant="outlined" size="large" elevation="2" @click="$router.push('/commands/hg')">
                    <v-icon :class="`mdi mdi-${icon} mr-2`" />
                    {{$lang === 'pt' ? 'Comandos' : 'Commands'}}
                </v-btn>
                <br />
                <v-btn class="mt-4 mb-12" color="white" variant="outlined" elevation="2" large href="https://discordapp.com/oauth2/authorize?client_id=472158887966015508&scope=applications.commands%20bot&permissions=242733149249">
                    <v-icon :class="`mdi mdi-${icon} mr-2`" />
                    <span v-if="$lang == 'pt'">Jogue Agora Mesmo!</span>
                    <span v-else>Play Right Now!</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    Name: "HellsGame",
    data: () => ({
        icon: 'fire'
    }),
    methods: {
        getImage(pic) {
            var images = require.context('@/assets/images/banner/', false, /\.png$/);
            return images('./' + pic + '.png')
        },
    },
    mounted() {
    }
}
</script>

<style>
h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h4 {
    text-align: justify;
    font-size: 1.2rem;
    line-height: 0.75rem;
}
</style>