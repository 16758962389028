<template>
    <div>
        <span v-if="$lang === 'pt'">
            Coloque o código a seguir no arquivo slash.js e se atente para inserir o id do seu bot ou servidor nos lugares necessários.
        </span>
        <span v-else>
            Put the following code in the file slash.js and pay atention to insert your bot's or guild's id in the right places.
        </span>
        <pre class="preslash bg-white my-8 px-2 overflow-auto">
            {{$lang === 'pt' ? code : codeen}}
        </pre>
        <div class="d-flex">
            <span v-if="$lang === 'pt'">
                Depois, convide seu bot ao seu servidor com o escopo <span class="text-slash">applications.commands</span> e quando o bot estiver em seu servidor vá no shell do seu projeto e execute o comando <span class="text-slash">node slash.js</span><br>Caso seu arquivo não esteja errado, os comandos slash serão criados.<br>Importante: Sempre que adicionar novos comandos no <span class="text-slash">slash.js</span>, você deve executar este comando novamente no shell.
            </span>
            <span v-else>
                Them, invite your bot to your guild with the scope <span class="text-slash">applications.commands</span> and when the bot enter your guild go to the shell of your project and run the command <span class="text-slash">node slash.js</span><br>If your file isn't wrong, the slash commands will be created.<br>Important: Always that you add new commands to <span class="text-slash">slash.js</span>, you must run this command again in the shell.
            </span>
            <v-icon color="white" :class="`mdi ${drop1 ? 'mdi-chevron-down' : 'mdi-chevron-right'} my-auto mx-2`" @click="drop1 = !drop1" />
        </div>
        <div v-if="drop1">
            <br>
            <p>
                <v-img class="flex" :src="image[0]" />    
            </p>
        </div>
    </div>
</template>

<script>
export default ({
    name: 'Slash',
    data: () => {
        return {
            drop1: false,
            image: ["https://i.imgur.com/9T3WgDO.png"],
            code: `
const { REST } = require('@discordjs/rest');
const { Routes } = require('discord-api-types/v9');

// Comando Simples: /command1
var slash1 = {
    name: "command1",
    description: "description1"
}

// Comando com opção string: /command2 option1: alguma-coisa
var slash2 = {
    name: "command2",
    description: "description2",
    options: [{
        name: "option1",
        description: "String",
        type: 3, // 3 = String
        required: true // Campo obrigatório, coloque false para deixar opcional
    }]
}

// Comando com opção integer: /command3 option1: 5
var slash3 = {
    name: "command3",
    description: "description3",
    options: [{
        name: "option1",
        description: "Number",
        type: 4, // 4 = Integer
        required: true // Campo obrigatório, coloque false para deixar opcional
    }]
}

// Comando com opção user: /command4 option1: Usuário
var slash4 = {
    name: "command4",
    description: "description4",
    options: [{
        name: "option1",
        description: "User",
        type: 6, // 6 = User
        required: true // Campo obrigatório, coloque false para deixar opcional
    }]
}

// Comando com opção user: /command5 option1: Canal
var slash5 = {
    name: "command5",
    description: "description5",
    options: [{
        name: "option1",
        description: "Channel",
        type: 7, // 7 = Channel
        required: true // Campo obrigatório, coloque false para deixar opcional
    }]
}

// Comando com opção role: /command6 option1: Cargo
var slash6 = {
    name: "command6",
    description: "description6",
    options: [{
        name: "option1",
        description: "Role",
        type: 8, // 8 = Role
        required: true // Campo obrigatório, coloque false para deixar opcional
    }]
}

// Comando de exemplo
var example = {
    name: "ping",
    description: "pong!"
}

// Coloque aqui todos os comandos do seu bot
// IMPORTANTE: não remova algum comando daqui mesmo que já o tenha adicionado, caso remova, o comando será deletado do bot!
const commands = [example];
// Exemplo de múltiplos comandos:
// const commands = [command1, command2, command3, ..., commandN];

(async () => {
// process.env.TOKEN é o seu token que está na variável de ambiente, que foi explicado no guia inicial
const rest = new REST({ version: '9' }).setToken(process.env.TOKEN);

try {
    console.log('Started refreshing application (/) commands.');

    // Adiciona os comandos slash para todos os servidores do bot - pode levar até 1 hora.
    await rest.put(
    Routes.applicationCommands("ID DO BOT"),
    { body: commands },
    );

    // Adiciona os comandos slash para um único servidor
    // await rest.put(
    //   Routes.applicationGuildCommands("ID DO BOT", "ID DO SERVIDOR"),
    //   { body: commands }
    // )

    console.log('Successfully reloaded application (/) commands.');
} catch (error) {
    console.error(error);
}
})();
            `,
            codeen: `
const { REST } = require('@discordjs/rest');
const { Routes } = require('discord-api-types/v9');

// Simple Command: /command1
var slash1 = {
    name: "command1",
    description: "description1"
}

// Command with option string: /command2 option1: something
var slash2 = {
    name: "command2",
    description: "description2",
    options: [{
        name: "option1",
        description: "String",
        type: 3, // 3 = String
        required: true // Required field, set false to make it optional
    }]
}

// Command with option integer: /command3 option1: 5
var slash3 = {
    name: "command3",
    description: "description3",
    options: [{
        name: "option1",
        description: "Number",
        type: 4, // 4 = Integer
        required: true // Required field, set false to make it optional
    }]
}

// Command with option user: /command4 option1: User
var slash4 = {
    name: "command4",
    description: "description4",
    options: [{
        name: "option1",
        description: "User",
        type: 6, // 6 = User
        required: true // Required field, set false to make it optional
    }]
}

// Command with option user: /command5 option1: Channel
var slash5 = {
    name: "command5",
    description: "description5",
    options: [{
        name: "option1",
        description: "Channel",
        type: 7, // 7 = Channel
        required: true // Required field, set false to make it optional
    }]
}

// Command with option role: /command6 option1: Role
var slash6 = {
    name: "command6",
    description: "description6",
    options: [{
        name: "option1",
        description: "Role",
        type: 8, // 8 = Role
        required: true // Required field, set false to make it optional
    }]
}

// Example Command
var example = {
    name: "ping",
    description: "pong!"
}

// Put here every command of your bot
// IMPORTANT: do not remove some command from here even if you're already added it, if you do, the command will be deleted from your bot!
const commands = [example];
// Multiples commands example:
// const commands = [command1, command2, command3, ..., commandN];

(async () => {
// process.env.TOKEN is your token from the environment variables, that was explained in the initial guide.
const rest = new REST({ version: '9' }).setToken(process.env.TOKEN);

try {
    console.log('Started refreshing application (/) commands.');

    // Add commands to every guild - can take up to 1 hour.
    await rest.put(
    Routes.applicationCommands("BOT ID"),
    { body: commands },
    );

    // Add slash commands only to one guild.
    // await rest.put(
    //   Routes.applicationGuildCommands("BOT ID", "GUILD ID"),
    //   { body: commands }
    // )

    console.log('Successfully reloaded application (/) commands.');
} catch (error) {
    console.error(error);
}
})();
            `
        }
    },
})
</script>

<style>
    .text-slash {
        color: #f52055 !important;
    }
    .preslash {
        max-height: 500px;
        border-left: 10px solid #f52055;
        border-radius: 0.2rem;
    }
</style>