<template>
  <div style="width: 90%; margin-left: 5%; height:100%; padding:1%;">
      <div class="text-white" style="margin-top:10%;margin-bottom:10%">
        <center>
            <v-img src="https://i.imgur.com/oGhYeRF.png" style="width:50%; height:auto" />
            <h1 class="text-4xl pt-8">{{$lang === 'pt' ? "A página que você está procurando não foi encontrada." : "The page you're looking for wasn't founded."}}</h1>
        </center>
      </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data: () => ({
  }),
  mounted() {
  }
}
</script>