<template>
    <div>
        <span v-if="$lang === 'pt'">Crie um arquivo chamado <span class="text-ch">CommandHandler.js</span> no seu projeto e insira o código abaixo no arquivo.</span>
        <span v-else>Create a new file in yuor project with the name <span class="text-ch">CommandHandler.js</span> and paste the code bellow inside of the file.</span>
        <pre class="preslash bg-white my-8 px-2 overflow-auto">
            {{code}}
        </pre>
        <span v-if="$lang === 'pt'">A utilização deste command handler no index será explicado na seção <a @click="goToSection('index')" href="#index" class="text-ch">Index</a>.<br>A estrutura de um comando a ser utilizada com este command handler será detalhada na seção <a @click="goToSection('commands')" href="#commands" class="text-ch">Comandos</a>.</span>
        <span v-else>The utilization of this command handler in the index file will be explicated in the section <a @click="goToSection('index')" href="#indexen" class="text-ch">Index</a>.<br> The command structure to be user with this command handler will be detailed in the section <a @click="goToSection('commands')" href="#commandsen" class="text-ch">Commands</a>.</span>
    </div>
</template>

<script>
export default {
    name: 'CommandHandler',
    data: () => {
        return {
            code: `
class CommandHandler {
    constructor(data = {}) {
        if (!data.folder) throw new Error("No folder specified.");
        this.folder = data.folder;
        if(!data.prefix) throw new Error("No prefix specified.");
        if (!Array.isArray(data.prefix)) data.prefix = [data.prefix];
        data.prefix.sort((a, b) => a.length < b.length);
        this.prefix = data.prefix;
        this._loadFrom(data.folder)
    }

    _loadFrom(folder) {
        const commands = new Map();
        const aliases = new Map();

        const fs = require("fs");

        const files = fs.readdirSync(folder);
        files.filter(f => fs.statSync(folder + f).isDirectory())
            .forEach(nested => fs.readdirSync(folder + nested).forEach(f => files.push(nested + '/' + f)));
        const jsFiles = files.filter(f => f.endsWith('.js'));

        if (files.length <= 0) throw new Error('No commands to load!');
        const fileAmount = \`\${jsFiles.length}\`;

        for (const f of jsFiles) {
            const file = require(folder + f);
            const cmd = new file();

            const name = cmd.name;
            commands.set(name, cmd);

            for (const alias of cmd.alias) {
                aliases.set(alias, name);
            }
        }

        console.log('Commands Loaded!');
        this.commands = commands;
        this.aliases = aliases;
    }

    getCommand(string) {

        if (!string) return null;

        let prefix = '';

        let prefixExists = false;

        for (const x of this.prefix) {
            if (string.startsWith(x)) {
                prefix = x;
                prefixExists = true;
                break;
            }
        }

        if (!prefixExists) return null;

        const command = string.substring(prefix.length);
        let cmd = this.commands.get(command);
        if (!cmd) {
            const alias = this.aliases.get(command);
            if (!alias) return null;
            cmd = this.commands.get(alias);
        }
        return cmd;
    }
}
module.exports = {
    CommandHandler
};`
        }
    },
    methods: {
        goToSection(section) {
            this.$emit('toSection', section);
        }
    }
}
</script>

<style>
    .text-ch {
        color: #6ee009 !important;
    }
    .prech {
        max-height: 500px;
        border-left: 10px solid #6ee009;
        border-radius: 0.2rem;
    }
</style>