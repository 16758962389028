import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import NotFound from '../views/Notfound.vue';
import Manut from '../views/Manutencao.vue';
import Cards from '../views/Cards.vue';
import HG from '../views/Hellsgame.vue';
import NotLogged from '../views/NotLogged.vue';
import Logged from '../views/Logged.vue';
import CommandsHG from '../views/CommandsHG.vue';

//import Cartas from '../views/CardsGame/CartasGame.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/play/cards/:room',
    name: 'Cartas Contra o Inferno',
    component: Manut
  },
  {
    path: '/commands/hg',
    name: 'CommandsHG',
    component: CommandsHG
  },
  {
    path: "/commands/cards",
    name: 'CommandsCards',
    component: Manut
  },
  {
    path: '/cards',
    name: 'Cards',
    component: Cards
  },
  {
    path: '/rpg',
    name: 'RPG',
    component: Manut
  },
  {
    path: '/hellsgame',
    name: 'HellsGame',
    component: HG
  },
  {
    path: '/notlogged',
    name: 'NotLogged',
    component: NotLogged
  },
  {
    path: '/logged',
    name: 'Logged',
    component: Logged
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
