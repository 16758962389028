<template>
    <v-footer class="w-full max-h-0 px-0">
        <v-card class='text-center w-full bnone' flat tile>
            <v-card-title class="lightred text-white">
                <div class="w-full py-4">
                    <div :class="['w-full justify-evenly my-auto text-light', $vuetify.display.mobile ? 'text-center' : 'd-flex']">
                        <div>
                            <h1>CRIADORES</h1>
                            <p>Gwynbleidd & Hyouka</p>
                        </div>
                        <div :class="$vuetify.display.mobile ? 'mb-4' : ''">
                            <h1 class="mb-4">REDES SOCIAIS</h1>
                            <div class="text-light">
                                <v-btn class="mx-2" color="white" elevation="2" icon outlined x-large href="https://www.instagram.com/erebus.bot/"><v-icon class="mdi mdi-instagram"></v-icon></v-btn>
                                <v-btn color="white" elevation="2" icon outlined x-large href="https://www.youtube.com/channel/UCDurvk_hjpoAGQUE_7GRpAw"><v-icon class="mdi mdi-youtube"></v-icon></v-btn>
                            </div>
                        </div>
                        <div>
                            <h1 class="mb-4">CONTATO</h1>
                            <div class="d-flex flex-column justify-content-center d-block">
                                <span><v-icon color="white" class="mdi mdi-email mr-2"></v-icon>contato@erebusbot.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-title>
            <v-card-text class="cprimary py-2 text-white text-center">
                <strong>Copyright © Erebus Project 2020</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>

export default {
  name: 'Footer',
  data: () => ({
  })
};
</script>

<style>
.bnone {
    border-radius: 0%;
}
</style>